import React from "react";
import styled from "styled-components";

const CommunityWrapper = styled.section`
    background: #ffebcd;
    color: #4b2c2c;
    padding: 50px 20px;
`;

const CommunityContainer = styled.div`
    max-width: 900px;
    margin: 0 auto;
`;

const CommunityTitle = styled.h1`
    font-size: 32px;
    font-weight: bold;
    color: #8b4513;
    margin-bottom: 20px;
    text-align: center;
`;

const CommunityText = styled.p`
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 20px;
`;

const CommunityList = styled.ul`
    margin: 20px 0;
    padding-left: 20px;
`;

const CommunityListItem = styled.li`
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 10px;
`;

const CommunityPolicy = () => {
    return (
        <CommunityWrapper>
            <CommunityContainer>
                <CommunityTitle>¡Únete a Vortex 2!</CommunityTitle>
                <CommunityText>
                    En **Vortex 2**, valoramos la colaboración y la creatividad en el desarrollo. Esta sección explica cómo puedes unirte a nuestra comunidad y contribuir a proyectos emocionantes.
                </CommunityText>

                <CommunityTitle>1. ¿Quiénes Somos?</CommunityTitle>
                <CommunityText>
                    Somos un grupo de desarrolladores apasionados que buscan construir y aprender juntos. Queremos que te unas a nosotros para:
                </CommunityText>
                <CommunityList>
                    <CommunityListItem>Conectar con otros entusiastas</CommunityListItem>
                    <CommunityListItem>Compartir conocimientos y habilidades</CommunityListItem>
                    <CommunityListItem>Crear proyectos innovadores</CommunityListItem>
                    <CommunityListItem>Disfrutar del proceso de desarrollo</CommunityListItem>
                </CommunityList>

                <CommunityTitle>2. Cómo Participar</CommunityTitle>
                <CommunityText>
                    Puedes participar de varias maneras:
                </CommunityText>
                <CommunityList>
                    <CommunityListItem>Unirte a nuestras reuniones semanales</CommunityListItem>
                    <CommunityListItem>Contribuir a proyectos de código abierto</CommunityListItem>
                    <CommunityListItem>Asistir a talleres y eventos</CommunityListItem>
                    <CommunityListItem>Colaborar en la creación de tutoriales y recursos</CommunityListItem>
                </CommunityList>

                <CommunityTitle>3. Nuestro Compromiso</CommunityTitle>
                <CommunityText>
                    En **Vortex 2**, nos comprometemos a crear un ambiente inclusivo y respetuoso. Creemos que cada miembro tiene algo valioso que aportar.
                </CommunityText>

                <CommunityTitle>4. Seguridad y Privacidad</CommunityTitle>
                <CommunityText>
                    Tomamos en serio la seguridad de nuestros miembros y sus datos. Implementamos medidas para proteger tu información personal.
                </CommunityText>

                <CommunityTitle>5. Tus Derechos</CommunityTitle>
                <CommunityText>
                    Tienes derecho a:
                </CommunityText>
                <CommunityList>
                    <CommunityListItem>Acceder a la información que compartes</CommunityListItem>
                    <CommunityListItem>Solicitar la corrección de tus datos</CommunityListItem>
                    <CommunityListItem>Retirar tu participación en cualquier momento</CommunityListItem>
                </CommunityList>

                <CommunityTitle>6. Contacto</CommunityTitle>
                <CommunityText>
                    Si tienes preguntas sobre cómo unirte a nosotros, contáctanos a:
                </CommunityText>
                <CommunityText>
                    📧 Email: contacto@vortex2.com
                </CommunityText>
                <CommunityText>
                    📍 Dirección: Calle de la Innovación, 15, 28000 Madrid, España
                </CommunityText>
            </CommunityContainer>
        </CommunityWrapper>
    );
};

export default CommunityPolicy;