import React from "react";
import styled from "styled-components";

const StyledProjectContainer = styled.section`
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    padding: 50px 15px;
    justify-content: center;
    background: linear-gradient(90deg, #f0f4c3, #dcedc8);
    box-sizing: border-box;
`;

const StyledImageContainer = styled.div`
    flex: 1;
    min-width: 280px;
    max-width: 550px;
    position: relative;
    overflow: hidden;
    border-radius: 16px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
`;

const StyledImage = styled.img`
    width: 100%;
    height: auto;
    display: block;
`;

const StyledContentContainer = styled.div`
    flex: 1;
    min-width: 280px;
    max-width: 550px;
    background-color: #e8f5e9;
    border-radius: 16px;
    padding: 25px 25px 35px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
`;

const StyledProjectTitle = styled.h2`
    font-size: 24px;
    color: #388e3c;
    margin-bottom: 15px;
    font-weight: bold;
    position: relative;
    z-index: 2;
`;

const StyledProjectDescription = styled.p`
    font-size: 16px;
    color: #1b5e20;
    line-height: 1.5;
    margin-bottom: 0;
    position: relative;
    z-index: 2;
`;

const Decoration = styled.div`
    content: "";
    position: absolute;
    top: 0;
    left: -15px;
    width: 6px;
    height: 100%;
    background-color: #388e3c;
    border-radius: 0 4px 4px 0;
    z-index: 1;
`;

const ProjectInfinityBlock = () => {
  return (
    <StyledProjectContainer>
      <StyledImageContainer>
        <StyledImage src="/infinity.jpg" alt="Vortex 2" />
      </StyledImageContainer>
      <StyledContentContainer>
        <Decoration />
        <StyledProjectTitle>Vortex 2: Creatividad y Comunidad</StyledProjectTitle>
        <StyledProjectDescription>
          Bienvenidos a Vortex 2, donde desarrolladores se unen para crear y compartir ideas innovadoras. 
          Nuestro proyecto fomenta la colaboración y el aprendizaje colectivo, ofreciendo un espacio para que todos contribuyan y crezcan juntos. 
          ¡Únete a nuestra comunidad y sé parte de la evolución!
        </StyledProjectDescription>
      </StyledContentContainer>
    </StyledProjectContainer>
  );
};

export default ProjectInfinityBlock;