import React, { useState } from "react";
import styled from "styled-components";

const SecciónPersonalizada = styled.section`
    width: 100%;
    padding: 30px;
    background-color: #f8d7da;
    box-sizing: border-box;
`;

const ContenedorPersonalizado = styled.div`
    max-width: 600px;
    margin: 30px auto;
`;

const ElementoPersonalizado = styled.div`
    border-bottom: 1px solid #f5c6cb;
    margin-bottom: 12px;
`;

const TituloPersonalizado = styled.h3`
    margin: 0;
    padding: 15px;
    font-size: 18px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f1b0b7;
    color: #722c2e;
    border-radius: 6px;
`;

const TextoPersonalizado = styled.div`
    padding: 15px;
    font-size: 16px;
    color: #722c2e;
    display: ${({ isOpen }) => (isOpen ? "block" : "none")};
    background-color: #ffffff;
    border-radius: 0 0 6px 6px;
`;

const FAQ = () => {
  const faqItems = [
    {
      question: "¿Qué es Vortex 2 y cómo funciona?",
      answer:
        "Vortex 2 es una plataforma innovadora que reúne a desarrolladores para crear proyectos colaborativos, fomentando la creatividad y el trabajo en equipo."
    },
    {
      question: "¿Cuáles son las ventajas de unirse a Vortex 2?",
      answer:
        "Al unirte a Vortex 2, tendrás acceso a tecnología avanzada, asesoramiento personalizado y formarás parte de una comunidad que impulsa el crecimiento profesional y personal."
    },
    {
      question: "¿Cómo puedo registrarme en Vortex 2?",
      answer:
        "El proceso de registro es sencillo: solo necesitas completar el formulario en nuestra página web y seguir las instrucciones para activar tu cuenta de inmediato."
    },
    {
      question: "¿Qué tipos de proyectos desarrolla Vortex 2?",
      answer:
        "Desarrollamos proyectos enfocados en inteligencia artificial, automatización y transformación digital, diseñados para revolucionar el trabajo en equipo."
    },
    {
      question: "¿Necesito experiencia previa para participar?",
      answer:
        "No se requiere experiencia previa. Vortex 2 está diseñado para ser accesible tanto para principiantes como para profesionales, ofreciendo recursos y apoyo en cada etapa."
    },
    {
      question: "¿Dónde puedo obtener más información?",
      answer:
        "Puedes encontrar más detalles en nuestra página web o contactar a nuestro equipo de soporte, que estará encantado de responder a todas tus preguntas."
    }
  ];

  const [openItems, setOpenItems] = useState(Array(faqItems.length).fill(false));

  const toggleItem = (index) => {
    setOpenItems((prev) => {
      const newOpenItems = [...prev];
      newOpenItems[index] = !newOpenItems[index];
      return newOpenItems;
    });
  };

  return (
    <SecciónPersonalizada>
      <ContenedorPersonalizado>
        {faqItems.map((item, index) => (
          <ElementoPersonalizado key={index}>
            <TituloPersonalizado onClick={() => toggleItem(index)}>
              {item.question} <span>{openItems[index] ? "–" : "+"}</span>
            </TituloPersonalizado>
            <TextoPersonalizado isOpen={openItems[index]}>{item.answer}</TextoPersonalizado>
          </ElementoPersonalizado>
        ))}
      </ContenedorPersonalizado>
    </SecciónPersonalizada>
  );
};

export default FAQ;