import CountUp from "react-countup";
import styled from "styled-components";
import { FaUsers, FaCode, FaHandshake } from "react-icons/fa";

const SeccionVortex = styled.section`
    background: linear-gradient(90deg, #ffe0b2, #ffcc80);
    padding: 50px 30px;
    color: #3e2723;
`;

const TituloVortex = styled.h2`
    text-align: center;
    font-size: 36px;
    margin-bottom: 50px;
    color: #ef6c00;
    font-weight: bold;
`;

const ContenedorGrillaVortex = styled.div`
    max-width: 1100px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 25px;
`;

const CajaVortex = styled.div`
    background: #ffe0b2;
    padding: 40px 30px;
    border-radius: 16px;
    text-align: center;
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.4s ease, box-shadow 0.4s ease;

    &:hover {
        transform: translateY(-10px);
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    }
`;

const ContenedorIconoVortex = styled.div`
    font-size: 42px;
    margin-bottom: 15px;
    color: #ef6c00;
`;

const TextoNumeroVortex = styled.div`
    font-size: 34px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #ef6c00;
`;

const DescripcionVortex = styled.div`
    font-size: 20px;
    color: #3e2723;
`;

const PorqueElegirVortex = () => {
  return (
    <SeccionVortex>
      <TituloVortex>¿Por qué elegir Vortex? </TituloVortex>
      <ContenedorGrillaVortex>
        <CajaVortex>
          <ContenedorIconoVortex>
            <FaUsers />
          </ContenedorIconoVortex>
          <TextoNumeroVortex>
            <CountUp end={56789} duration={3} separator="," />
          </TextoNumeroVortex>
          <DescripcionVortex>Desarrolladores en nuestra comunidad</DescripcionVortex>
        </CajaVortex>
        <CajaVortex>
          <ContenedorIconoVortex>
            <FaCode />
          </ContenedorIconoVortex>
          <TextoNumeroVortex>
            <CountUp end={92} duration={3} separator="," />
          </TextoNumeroVortex>
          <DescripcionVortex>Proyectos Colaborativos</DescripcionVortex>
        </CajaVortex>
        <CajaVortex>
          <ContenedorIconoVortex>
            <FaHandshake />
          </ContenedorIconoVortex>
          <TextoNumeroVortex>
            <CountUp end={9876} duration={3} separator="," />
          </TextoNumeroVortex>
          <DescripcionVortex>Conexiones Sólidas</DescripcionVortex>
        </CajaVortex>
      </ContenedorGrillaVortex>
    </SeccionVortex>
  );
};
export default PorqueElegirVortex;