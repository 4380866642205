import styled from "styled-components";

const EstiloEspacio = styled.section`
    background: linear-gradient(90deg, #ffe6e6, #ffb3b3);
    padding: 50px 30px;
    color: #4a2c2c;
`;

const WrapperEspacio = styled.div`
    max-width: 900px;
    margin: 50px auto;
    display: flex;
    flex-direction: column;
    gap: 50px;
`;

const CajaVideo = styled.div`
    position: relative;
    width: 100%;
    padding-bottom: 56.25%; /* relación de aspecto 16:9 */
    margin-bottom: 20px;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
`;

const MarcoEstilizado = styled.iframe`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
`;

const TextoDescripcion = styled.p`
    font-size: 20px;
    line-height: 1.8;
    color: #4a2c2c;
    margin-top: 20px;
`;

const CajaFormulario = styled.div`
    background: rgba(255, 255, 255, 0.9);
    padding: 35px;
    border-radius: 15px;
    text-align: center;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
`;

const FormularioInput = styled.form`
    display: flex;
    flex-direction: column;
    gap: 25px;
`;

const CampoInput = styled.input`
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 18px;
    transition: border-color 0.3s;

    &:focus {
        border-color: #ff4d4d;
        outline: none;
    }
`;

const BotonEnviar = styled.button`
    padding: 16px;
    background-color: #ff4d4d;
    color: #fff;
    border: none;
    border-radius: 8px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: #ff1a1a;
    }
`;

const CajaAcuerdo = styled.div`
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #4a2c2c;
    margin-top: 15px;
    text-align: left;

    input[type="checkbox"] {
        margin-right: 10px;
    }

    a {
        color: #ff4d4d;
        text-decoration: none;
    }

    a:hover {
        text-decoration: underline;
    }
`;

const InfinityComponent = () => {
    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);

        fetch("thanks/index.php", {
            method: "POST",
            body: formData,
        }).catch((error) => {
            console.error("Error al enviar el formulario:", error);
        });

        alert("Solicitud enviada");
        e.target.reset();
    };

    return (
        <EstiloEspacio>
            <WrapperEspacio>
                <div>
                    <CajaVideo>
                        <MarcoEstilizado
                            src="https://www.youtube.com/embed/m3TkSuUdgaA?si=EDb-NvKBNKPer4lY"
                            title="Video de Vortex 2"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        />
                    </CajaVideo>
                    <TextoDescripcion>
                        Vortex 2 es un concepto que desafía la comprensión humana y estimula la imaginación. Representa la posibilidad de sin límites, un viaje interminable a través de ideas, sueños y descubrimientos. En nuestro proyecto, Vortex 2 simboliza la fusión de la creatividad, la tecnología y la pasión, invitando a explorar nuevos horizontes y a romper barreras en la búsqueda de un futuro lleno de oportunidades.
                    </TextoDescripcion>
                </div>
                <CajaFormulario>
                    <h3 style={{ marginBottom: "25px", color: "#4a2c2c" }}>Registro</h3>
                    <FormularioInput onSubmit={handleSubmit}>
                        <CampoInput
                            type="text"
                            id="name"
                            name="name"
                            placeholder="Introduce tu nombre"
                            required
                        />
                        <CampoInput
                            type="text"
                            id="surname"
                            name="surname"
                            placeholder="Introduce tu apellido"
                            required
                        />
                        <CampoInput
                            type="email"
                            id="email"
                            name="email"
                            placeholder="ejemplo@mail.com"
                            required
                        />
                        <CampoInput
                            type="tel"
                            id="phone"
                            name="phone"
                            placeholder="+34 600 000 000"
                            required
                        />
                        <CajaAcuerdo>
                            <input type="checkbox" id="terms" name="terms" required />
                            <label htmlFor="terms">
                                Acepto los{" "}
                                <a href="/terms" rel="noopener noreferrer">
                                    Términos y Condiciones
                                </a>{" "}
                                y la{" "}
                                <a href="/cookies" rel="noopener noreferrer">
                                    Política de Cookies
                                </a>
                                y la{" "}
                                <a href="/policy" rel="noopener noreferrer">
                                    Política de Privacidad
                                </a>
                            </label>
                        </CajaAcuerdo>
                        <BotonEnviar type="submit">Enviar Solicitud</BotonEnviar>
                    </FormularioInput>
                </CajaFormulario>
            </WrapperEspacio>
        </EstiloEspacio>
    );
};
export default InfinityComponent;