import React from "react";
import styled from "styled-components";

const CustomFooter = styled.footer`
  background: linear-gradient(90deg, #D32F2F, #FF7043);
  color: #f5f5f5;
  padding: 50px 20px 30px;
  box-sizing: border-box;
  border-top: 2px solid rgba(255, 255, 255, 0.7);
`;

const CustomFooterContent = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 30px;
`;

const CustomSection = styled.div`
  flex: 1;
  min-width: 200px;
`;

const CustomSectionTitle = styled.h3`
  font-size: 22px;
  margin-bottom: 12px;
  font-weight: 500;
  color: #FFEB3B;
`;

const CustomText = styled.p`
  font-size: 14px;
  margin-bottom: 6px;
  line-height: 1.4;
`;

const CustomFooterLink = styled.a`
  color: #f5f5f5;
  text-decoration: none;
  font-size: 14px;
  display: block;
  margin-bottom: 6px;
  transition: color 0.3s;

  &:hover {
    color: #FFEB3B;
  }
`;

const CustomFooterBottom = styled.div`
  text-align: center;
  border-top: 1px solid rgba(255, 255, 255, 0.6);
  padding-top: 15px;
  font-size: 12px;
  margin-top: 20px;
`;

const Footer = () => {
  return (
    <CustomFooter>
      <CustomFooterContent>
        <CustomSection>
          <CustomSectionTitle>Vortex 2</CustomSectionTitle>
          <CustomText>Dirección: Calle de la Innovación, 28000 Madrid, España</CustomText>
          <CustomText>Teléfono: +34 912 34 56 78</CustomText>
          <CustomText>Email: contacto@vortex2.es</CustomText>
        </CustomSection>
        <CustomSection>
          <CustomSectionTitle>Enlaces</CustomSectionTitle>
          <CustomFooterLink href="/" target="_blank" rel="noopener noreferrer">
            Inicio
          </CustomFooterLink>
          <CustomFooterLink href="/about" target="_blank" rel="noopener noreferrer">
            Acerca de
          </CustomFooterLink>
          <CustomFooterLink href="/contact" target="_blank" rel="noopener noreferrer">
            Contacto
          </CustomFooterLink>
          <CustomFooterLink href="/policy" target="_blank" rel="noopener noreferrer">
            Política de privacidad
          </CustomFooterLink>
        </CustomSection>
        <CustomSection>
          <CustomSectionTitle>Síguenos</CustomSectionTitle>
          <CustomFooterLink
            href="https://www.facebook.com/Vortex2"
            target="_blank"
            rel="noopener noreferrer"
          >
            Facebook
          </CustomFooterLink>
          <CustomFooterLink
            href="https://twitter.com/Vortex2"
            target="_blank"
            rel="noopener noreferrer"
          >
            Twitter
          </CustomFooterLink>
          <CustomFooterLink
            href="https://www.linkedin.com/company/Vortex2"
            target="_blank"
            rel="noopener noreferrer"
          >
            LinkedIn
          </CustomFooterLink>
          <CustomFooterLink
            href="https://www.instagram.com/Vortex2"
            target="_blank"
            rel="noopener noreferrer"
          >
            Instagram
          </CustomFooterLink>
        </CustomSection>
      </CustomFooterContent>
      <CustomFooterBottom>
        &copy; {new Date().getFullYear()} Vortex 2. Todos los derechos reservados.
      </CustomFooterBottom>
    </CustomFooter>
  );
};

export default Footer;