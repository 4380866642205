import React from "react";
import styled from "styled-components";

const Wrapper = styled.section`
    width: 100%;
    padding: 60px 20px;
    background: linear-gradient(90deg, #ffe4e1, #ffb6c1);
    box-sizing: border-box;
    color: #9b5757;
`;

const Heading = styled.h2`
    text-align: center;
    font-size: 34px;
    margin-bottom: 50px;
    color: #a0522d;
    font-weight: bold;
`;

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 30px;
    justify-items: center;
`;

const Card = styled.div`
    background: rgba(255, 255, 255, 0.9);
    padding: 30px 20px;
    border-radius: 12px;
    width: 100%;
    max-width: 320px;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
        transform: translateY(-8px);
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
    }
`;

const Photo = styled.img`
    width: 70px;
    height: 70px;
    border-radius: 50%;
    object-fit: cover;
    margin: 0 auto 15px auto;
    display: block;
    border: 3px solid #ffb6c1;
`;

const Name = styled.h3`
    font-size: 20px;
    margin-bottom: 5px;
    color: #a0522d;
    text-align: center;
`;

const Position = styled.p`
    font-size: 14px;
    margin-bottom: 15px;
    color: #9b5757;
    text-align: center;
    opacity: 0.8;
`;

const Text = styled.p`
    font-size: 16px;
    line-height: 1.5;
    color: #9b5757;
    text-align: center;
`;

const data = [
  {
    photo: "test1.jpg",
    name: "Sofia Dupont",
    position: "Desarrolladora, Comunidad Vortex",
    review:
      "Vortex 2 ha transformado nuestra forma de colaborar. Su enfoque amigable y profesional ha elevado nuestro trabajo conjunto."
  },
  {
    photo: "test3.jpg",
    name: "Carlos Lefevre",
    position: "Líder Técnico, Comunidad Vortex",
    review:
      "La creatividad y el compromiso del equipo de Vortex 2 hacen toda la diferencia. Sus soluciones innovadoras nos han ayudado a superar desafíos."
  },
  {
    photo: "test2.jpg",
    name: "Elena Martin",
    position: "Directora de Proyectos, Comunidad Vortex",
    review:
      "Colaborar con Vortex 2 ha sido una experiencia enriquecedora. Su atención personalizada y experiencia han elevado nuestro trabajo en equipo."
  }
];

const Testimonials = () => {
  return (
    <Wrapper>
      <Heading>Testimonios de la Comunidad</Heading>
      <Grid>
        {data.map((item, index) => (
          <Card key={index}>
            <Photo src={item.photo} alt={item.name} />
            <Name>{item.name}</Name>
            <Position>{item.position}</Position>
            <Text>{item.review}</Text>
          </Card>
        ))}
      </Grid>
    </Wrapper>
  );
};

export default Testimonials;