import React from "react";
import styled from "styled-components";

const VortexContainer = styled.section`
  width: 100%;
  padding: 50px 20px;
  background: linear-gradient(90deg, #e63946, #f1faee);
  box-sizing: border-box;
  text-align: center;
`;

const VortexTitre = styled.h2`
  font-size: 36px;
  color: #1d3557;
  margin-bottom: 20px;
`;

const VortexTexte = styled.p`
  font-size: 18px;
  color: #457b9d;
  margin-bottom: 30px;
`;

const VortexBouton = styled.a`
  display: inline-block;
  padding: 15px 30px;
  background-color: #f1faee;
  color: #1d3557;
  font-size: 20px;
  font-weight: bold;
  text-decoration: none;
  border-radius: 8px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #a8dadc;
  }
`;

const CTA = () => {
  return (
    <VortexContainer>
      <VortexTitre>¡Únete a Vortex 2!</VortexTitre>
      <VortexTexte>
        Sé parte de una comunidad de desarrolladores que crea proyectos asombrosos juntos.
      </VortexTexte>
      <VortexBouton href="/contact">Contáctanos ahora</VortexBouton>
    </VortexContainer>
  );
};

export default CTA;