import React from "react";
import styled from "styled-components";

const CustomContainer = styled.section`
    width: 100%;
    padding: 60px 20px;
    background: #ffe4e1;
    box-sizing: border-box;
`;

const Heading = styled.h2`
    text-align: center;
    font-size: 40px;
    margin-bottom: 50px;
    color: #d2691e;
    font-weight: bold;
`;

const CustomGrid = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
`;

const CustomCard = styled.div`
    background: #fffaf0;
    border-radius: 12px;
    overflow: hidden;
    max-width: 280px;
    flex: 1;
    min-width: 240px;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
        transform: translateY(-8px);
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
    }
`;

const CustomPhoto = styled.img`
    width: 100%;
    height: 250px;
    object-fit: cover;
`;

const CustomInfo = styled.div`
    padding: 20px;
    text-align: center;
`;

const CustomName = styled.h3`
    font-size: 24px;
    margin-bottom: 10px;
    color: #d2691e;
`;

const CustomRole = styled.p`
    font-size: 18px;
    color: #8b4513;
    margin-bottom: 14px;
    font-weight: 500;
`;

const CustomDescription = styled.p`
    font-size: 16px;
    color: #483d8b;
    line-height: 1.5;
`;

const customData = [
  {
    photo: "team1.jpg",
    name: "María López",
    role: "Coordinadora de Comunidad",
    description:
      "Apasionada por la colaboración y la creación de proyectos, invita a todos a unirse a nuestra comunidad de desarrolladores."
  },
  {
    photo: "team2.jpg",
    name: "Javier Pérez",
    role: "Líder Técnico",
    description:
      "Entusiasta de la programación, comprometido a desarrollar soluciones innovadoras y eficaces junto a otros."
  },
  {
    photo: "team3.jpg",
    name: "Lucía Ruiz",
    role: "Gestora de Proyectos",
    description:
      "Experta en gestión de equipos, dedicada a fomentar un ambiente creativo y productivo para todos."
  },
  {
    photo: "team4.jpg",
    name: "Carlos Sánchez",
    role: "Diseñador de Experiencia",
    description:
      "Creativo que se enfoca en hacer que cada proyecto sea memorable, invitando a más personas a unirse a nuestra aventura."
  }
];

const CustomTeam = () => {
  return (
    <CustomContainer>
      <Heading>Únete a Nuestro Proyecto Vortex 2</Heading>
      <CustomGrid>
        {customData.map((member, index) => (
          <CustomCard key={index}>
            <CustomPhoto src={member.photo} alt={member.name} />
            <CustomInfo>
              <CustomName>{member.name}</CustomName>
              <CustomRole>{member.role}</CustomRole>
              <CustomDescription>{member.description}</CustomDescription>
            </CustomInfo>
          </CustomCard>
        ))}
      </CustomGrid>
    </CustomContainer>
  );
};

export default CustomTeam;