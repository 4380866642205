import styled from "styled-components";

const VortexWrapper = styled.section`
  width: 100%;
  padding: 50px 15px;
  background: linear-gradient(45deg, #ff9999, #ffe6cc);
  box-sizing: border-box;
`;

const VortexContainer = styled.div`
  max-width: 900px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 25px;
`;

const VortexInfo = styled.div`
  flex: 1;
  min-width: 250px;
  text-align: center;
`;

const VortexImage = styled.img`
    max-width: 100%;
    border-radius: 12px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
`;

const VortexText = styled.div`
    flex: 1;
    min-width: 250px;
`;

const VortexHeader = styled.h2`
    font-size: 32px;
    color: #d9534f;
    margin-bottom: 15px;
    font-weight: bold;
`;

const VortexParagraph = styled.p`
    font-size: 16px;
    color: #5d4037;
    line-height: 1.6;
    margin-bottom: 15px;
`;

const About = () => {
  return (
    <VortexWrapper>
      <VortexContainer>
        <VortexText>
          <VortexHeader>Acerca de Vortex 2</VortexHeader>
          <VortexParagraph>
            En Vortex 2, estamos dedicados a construir un futuro colaborativo mediante la creación de soluciones digitales innovadoras. Invitamos a desarrolladores a unirse a nuestra comunidad para explorar y crear juntos.
          </VortexParagraph>
          <VortexParagraph>
            Nuestro equipo apasionado trabaja con creatividad y visión, enfrentando los desafíos del mañana y ofreciendo tecnología de vanguardia que abre nuevas oportunidades para la colaboración. ¡Ven y descubre un mundo lleno de posibilidades!
          </VortexParagraph>
        </VortexText>
      </VortexContainer>
    </VortexWrapper>
  );
};
export default About;