import styled from "styled-components";

const StyledContainer = styled.section`
    width: 100%;
    padding: 40px 10px;
    background: linear-gradient(135deg, #ffebcc, #ffe5d1);
    box-sizing: border-box;
`;

const StyledBlock = styled.div`
    max-width: 700px;
    margin: 30px auto;
    padding: 30px 20px;
    background-color: #d6d6d6;
    border-radius: 10px;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
`;

const StyledHeading = styled.h1`
    text-align: center;
    font-size: 32px;
    margin-bottom: 15px;
    color: #cc5500;
    font-weight: bold;
`;

const StyledText = styled.div`
    font-size: 16px;
    line-height: 1.5;
    color: #4b0082;

    p {
        margin-bottom: 15px;
    }

    a {
        color: #cc5500;
        text-decoration: none;
        font-weight: 500;
    }

    a:hover {
        text-decoration: underline;
    }
`;

const CookiePolicy = () => {
  return (
    <StyledContainer>
      <StyledBlock>
        <StyledHeading>Únete a la Comunidad Vortex 2</StyledHeading>
        <StyledText>
          <p>
            En Vortex 2, valoramos la colaboración y la creatividad. Nuestro objetivo es reunir a desarrolladores apasionados que deseen crear proyectos juntos y compartir ideas innovadoras.
          </p>
          <p>
            Creemos que la unión hace la fuerza y que trabajar en equipo enriquece nuestras capacidades. Te invitamos a explorar nuevas oportunidades y a contribuir a nuestra comunidad vibrante.
          </p>
          <p>
            Puedes unirte a nosotros y participar en proyectos colaborativos, donde cada voz cuenta y cada idea tiene valor. ¡Juntos, podemos lograr grandes cosas!
          </p>
          <p>
            Para obtener más detalles, consulta nuestra{" "}
            <a href="/privacy" target="_blank" rel="noopener noreferrer">
              Política de Privacidad
            </a>.
          </p>
        </StyledText>
      </StyledBlock>
    </StyledContainer>
  );
};
export default CookiePolicy;