import styled from "styled-components";

const NewWrapper = styled.div`
    max-width: 900px;
    margin: 60px auto 30px;
    padding: 30px 20px;
    background-color: #fff3e0;
    border-radius: 15px;
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.4);
`;

const NewHeaderText = styled.h1`
    text-align: center;
    font-size: 32px;
    margin-bottom: 20px;
    color: #e64a19;
    font-weight: bold;
`;

const NewTextContent = styled.div`
    font-size: 20px;
    line-height: 1.8;
    color: #4e342e;

    p {
        margin-bottom: 15px;
    }

    strong {
        color: #e64a19;
    }
`;

const TermsAndConditions = () => {
  return (
    <>
      <NewWrapper>
        <NewHeaderText>¡Únete a la Comunidad Vortex 2!</NewHeaderText>
        <NewTextContent>
          <p>
            Bienvenido a Vortex 2. Al acceder a nuestra plataforma, te invitamos a ser parte de nuestra comunidad de desarrolladores, donde colaboramos en proyectos emocionantes y creativos.
          </p>
          <p>
            <strong>1. Participación en el sitio:</strong> Los miembros se comprometen a interactuar de manera respetuosa y positiva, fomentando la colaboración y el intercambio de ideas.
          </p>
          <p>
            <strong>2. Propiedad del contenido:</strong> Todo el contenido compartido en Vortex 2 es de propiedad de los autores y está protegido por derechos de autor. Se anima a los participantes a dar crédito y reconocer las contribuciones de los demás.
          </p>
          <p>
            <strong>3. Responsabilidad:</strong> Vortex 2 no se hace responsable de las decisiones tomadas por los miembros basadas en la información compartida en la plataforma. Cada uno es responsable de sus acciones.
          </p>
          <p>
            <strong>4. Actualizaciones y modificaciones:</strong> Nos reservamos el derecho de modificar estas pautas en cualquier momento. La participación continua implica la aceptación de los cambios.
          </p>
          <p>
            <strong>5. Legislación:</strong> Estas pautas se regirán por las leyes españolas. En caso de disputa, las partes se someterán a la jurisdicción exclusiva de los tribunales de Madrid.
          </p>
          <p>
            Si tienes preguntas o necesitas más información, no dudes en contactarnos. ¡Estamos aquí para ayudarte a crecer!
          </p>
        </NewTextContent>
      </NewWrapper>
    </>
  );
};
export default TermsAndConditions;